import React from 'react'
import {connect} from 'react-redux'
import Cookie from './Cookie'
import { USER_TYPE, FEATURE_NAME_ALONG_WITH_URL } from 'app/constants'
import * as userAuthInfo from 'app/actions/user'
import SessionData from 'app/utils/sessionData'
import { extractParameters } from 'app/utils/dataFormater'
class AuthenticateUser extends React.Component {
  constructor(props){
    super(props);

    let openRoutes = ["forgotPassword", "resetpassword", "graphCalculator", "calculator", "public", "mfuOrderAuth", "onBoarding", "onboardingMFU", "onboardingBSE", "policies", "riskProfileMailer", 'kycOnBoarding', "appStore"] 
    if(localStorage && localStorage.getItem('isPopupOpen')){
      localStorage.removeItem('isPopupOpen')
    }
    let getURL = window.location.href
    let { FeatureName, isin } = extractParameters(getURL, ['FeatureName', 'isin'])
    const isFeatureNameValid = FEATURE_NAME_ALONG_WITH_URL[FeatureName]
    if (isFeatureNameValid) {
      SessionData.setItem('FeatureName', isFeatureNameValid)
      SessionData.setItem('isin', isin)
    }
    const isUrlContainIsin = getURL && getURL.split("?") && getURL.split("?")[1] && getURL.split("?")[1].includes('isin')
    if ((!sessionStorage.getItem('SchemeIsin') && isUrlContainIsin)) {
      SessionData.setItem('SchemeIsin', getURL)
    }
    else {
      sessionStorage.removeItem('SchemeIsin')
    }
     var loginStatusInterval = setInterval(()=>{
        if(Cookie.getCookie('c_ux')){
          localStorage && localStorage.removeItem('isPopupOpen')
          if(!sessionStorage.getItem('localUrl')){
            let userType = location.hostname.split('.')[0]
            if(userType == 'admin' || userType == 'admin-demo'){
              window.location.href ='#/admin/ifaLookup';

            }else{
            let getHashName = window.location.href.split('#')[1].split('/')[1]
            let currentUrl = getHashName && getHashName.split('?')[0]
            if(currentUrl == 'subscribe'){
              getHashName = currentUrl
            }
            let isOpenRouteNot = this.checkOpenRoutes(openRoutes,getHashName)
              switch(getHashName){
                case 'client':
                window.location.href = '#/client/dashboard'
                break;
                case 'resetpassword':
                let logoutWithoutRedirection = {}
                    logoutWithoutRedirection.logout = true
                props.dispatch(userAuthInfo.logout(logoutWithoutRedirection))
                break;
                case isOpenRouteNot:
                case 'subscribe': 
                  break;

                default:
                window.location.href = '#/broker/dashboard'
                break;
              }
            }
            clearInterval(loginStatusInterval)
          }
          // props.dispatch(userAuthInfo.GetUserData())
        }
        if(!Cookie.getCookie('c_ux')){
          let getURL = window.location.href
          let previousUrl = window.location.href.split('#')
            previousUrl = previousUrl[1].split('/')
            previousUrl = previousUrl[1] ? (previousUrl[1].split('?')[0]) : previousUrl[1]
          if(previousUrl != 'login'){
            SessionData.setItem('previousURLBeforeLogout', getURL)
          }
          if( (location.hostname.split('.')[0] == 'sociallogin')){
            let socialloginInterval = setInterval(()=>{
              if( ((!localStorage.getItem('isPopupOpen') ) && (localStorage.getItem('isPopupOpen') != null) ) ){
                let domain = window.location.href.split('=')[1]
                window.location.assign("https://"+domain+".investwell.app/app/#/login?fromAuthCheck")
                clearInterval(socialloginInterval)
              }
            },1500)
          }
          let forgetResetRoute = getURL.split('#')[1].split('/')[1]
          let isOpenRouteNot = this.checkOpenRoutes(openRoutes,forgetResetRoute)
          switch(forgetResetRoute){
            case isOpenRouteNot:
              break;
            case forgetResetRoute:
              if(forgetResetRoute.split('?')[1]){
                break
              }
            default:
              if(forgetResetRoute != 'login'){
                window.location = '#/login?fromAuthCheck'
              }
            break;
          }
        }
    },250)

  }
  checkOpenRoutes(openRoutes,getHashName){
    let isOpenRoute = false
    for(let itr=0; itr<openRoutes.length; itr++ ){
      if (openRoutes[itr]== getHashName) {
        return isOpenRoute = openRoutes[itr]
      }
    }
  }
  splitUrl(url){
    let getOrigin = url
        getOrigin = getOrigin && getOrigin.split('#')

    let brokerDomainWithProtocol = getOrigin && getOrigin[0].split('.')
        if (brokerDomainWithProtocol) {
            brokerDomainWithProtocol= brokerDomainWithProtocol[0]
        }

    let ourAppHost = getOrigin && getOrigin[0].split('.')
        if (ourAppHost) {
        ourAppHost = ourAppHost[1]+'.'+ourAppHost[2]
        }

    let ourApp = ourAppHost && ourAppHost.split('/')
        if (ourApp) {
          ourApp = ourApp[0]
        }
        
    let urlhash = getOrigin && getOrigin[1]


    return [brokerDomainWithProtocol, ourApp, urlhash]
  }
  previousURLBeforeLogout(newProps){
    let savedUrl = this.splitUrl(SessionData.parseAndGetItem('previousURLBeforeLogout'))
    let currentHittedurl = this.splitUrl(window.location.href)
    let savedDomain, savedAppName, hittedAppName, hittedDomain
      savedDomain = savedUrl[0]
      hittedDomain = currentHittedurl[0]
      savedAppName = savedUrl[1]
      hittedAppName = currentHittedurl[1]

    let urlhash = savedUrl[2]
    let userPortal = urlhash&&urlhash.split('/')[1]
    if(savedUrl){
      if(newProps.userdata&&newProps.userdata.user){
        if(newProps.userdata.user.userType == USER_TYPE.broker){
          if (savedDomain === hittedDomain && savedAppName === hittedAppName && userPortal === USER_TYPE.broker) {
            window.location.href = '#'+urlhash
          }else {
            window.location.href = '#/broker/dashboard'
          }
        }
        if(newProps.userdata.user.userType == USER_TYPE.client){
          if (savedDomain === hittedDomain && savedAppName === hittedAppName && userPortal === USER_TYPE.client) {
            window.location.href = '#'+urlhash
          }else {
            window.location.href = '#/client/dashboard'
          }
        }
        if(newProps.userdata.user.userType == USER_TYPE.admin){
            window.location.href = '#/admin/ifaLookup';
          }

        // sessionStorage.removeItem('previousURLBeforeLogout',null)
      }
    }

    if(savedUrl){
      if(newProps.userdata&&newProps.userdata.user&&newProps.userdata.user.userGroup){
        if(newProps.userdata.user.userGroup.userType == USER_TYPE.broker){
          if (savedDomain === hittedDomain && savedAppName === hittedAppName && userPortal === USER_TYPE.broker) {
            window.location.href = '#'+urlhash
          }else {
            window.location.href = '#/broker/dashboard'
          }
        }
        if(newProps.userdata.user.userGroup.userType == USER_TYPE.client){
          if (savedDomain === hittedDomain && savedAppName === hittedAppName && userPortal === USER_TYPE.client) {
            window.location.href = '#'+urlhash
          }else{
            window.location.href = '#/client/dashboard'
          }
        }
        if(newProps.userdata.user.userGroup.userType == USER_TYPE.admin){
            window.location.href = '#/admin/ifaLookup' ;
          }

        // sessionStorage.removeItem('previousURLBeforeLogout',null)
      }
    }
  }
  UNSAFE_componentWillReceiveProps(newProps){
    
    if((newProps.userdata&&newProps.userdata.user && newProps.userdata.user.apiStatus == 0) || (newProps.userdata&&newProps.userdata.user&&newProps.userdata.user.userGroup)){
        // this.previousURLBeforeLogout(newProps)
    }
   
  }

  render() {
    return (this.props.children)
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userdata :state.userReducer

  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(AuthenticateUser)

