var initialState = {}
export default  function complianceReport( state = initialState, action ){
    switch(action.type){
    case 'CLEAR_NOTIFICATION':
        return {...state, notificationStatus: action.payload}

    case 'GOT_COMPLIANCE_REPORT_LIST' :
        return { ...state, complianceReportList : action.payload }
    case 'FAILED_COMPLIANCE_REPORT_LIST' :
        return {...state, complianceReportList : action.payload }
    
    case 'COMPONENT_LOADER_FOR_COMPLIANCE_LIST' :
        return { ...state, loaderForComplianceList : action.payload }
    
    case 'COMPLIANCE_UPDATED_SUCCESSFULLY' : 
        return {...state, notificationStatus:action.payload, errorNotification: false }
    case 'FAILED_COMPLIANCE_UPDATE' : 
        return {...state, notificationStatus:action.payload, errorNotification: true }
    
    case 'SUCCESS_COMMUNICATION' : 
        return {...state, notificationStatus:action.payload, errorNotification: false }
    case 'FAILED_COMMUNICATION' : 
        return {...state, notificationStatus:action.payload, errorNotification: true }

    case 'SUCCESS_TAGS_IN_CLIENTS' : 
        return {...state, notificationStatus:action.payload, errorNotification: false }
    case 'FAILED_TAGS_IN_CLIENTS' : 
        return {...state, notificationStatus:action.payload, errorNotification: true }
    case 'GOT_UPDATED_CAMS_KARVY':
        return {...state, complianceCamsKarvyData : action.payload }
    case 'FAILED_UPDATED_CAMS_KARVY':
        return {...state, complianceCamsKarvyData : action.payload }
    case 'GOT_REFRESHED_COMPLIANCE_DATA':
        return {...state, notificationStatus : action.payload,errorNotification: false }
    case 'FAILED_REFRESHED_COMPLIANCE_DATA':
        return {...state, notificationStatus : action.payload, errorNotification: true }    
        default:
        new Error('No action type matched in reducer')
    }
    return state
}