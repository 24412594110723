import React from 'react'
import Cookie from './Cookie'
import {connect} from 'react-redux'
import * as userAuthInfo from 'app/actions/user';
import Loader from 'app/uiCollection/shared/loaders'
import { LOADER_WIDTH } from 'app/constants/shared/commonConst'
import SessionData from 'app/utils/sessionData'
import { dependencyCheck } from 'app/utils/dataFormater'
class AuthenticateUser extends React.Component {
  // logOutCheck:''
  logOutCheck=null
  constructor(props){
    super(props);
    if(Cookie.getCookie('c_ux')){
      this.props.dispatch(userAuthInfo.GetUserData());
    }
    else {
      let getURL = window.location.href
      // SessionData.setItem('previousURLBeforeLogout', getURL)
      props.dispatch(userAuthInfo.logout(this.logOutCheck));
    }
    this.state = {}
  }

  UNSAFE_componentWillMount() {
    const { dispatch, currentURL,lastApiCallTime } = this.props;
    let lastApiCall
    let websiteAndCustomUrl= ((this.props.userTheme && (this.props.userTheme.website && this.props.userTheme.allowSSOLogin == 1)) && this.props.ssoToken) ? this.props.userTheme.website : this.props.userTheme &&this.props.userTheme.customURL

    /*if (SessionData.parseAndGetLocalItem('getActualTime')) {
      lastApiCall = SessionData.parseAndGetLocalItem('getActualTime')
    }*/
    if (this.props.SSOuserData && this.props.SSOuserData.status == 0) {
      let featureName = sessionStorage.getItem('FeatureName')
      let isin = sessionStorage.getItem('isin')
      featureName = featureName.replace(/"/g, '')
      isin = isin && isin.replace(/"/g, '')
      const extraParam = isin ? `?isin=${isin}` : ''
      window.location.href = `/app/#/client/${featureName}${extraParam}`
      dispatch({ type: "SSO_LOGIN_IN_RESPONCE", payload: null })
    }
    if(Cookie.getCookie('c_ux')){
      this.logOutCheck = setInterval(
        () =>{
        if (SessionData.parseAndGetLocalItem('getActualTime')) {
          lastApiCall = SessionData.parseAndGetLocalItem('getActualTime')
        }      
        if (lastApiCall > 0) {
          var currentTime = new Date();
          var timeDiff = currentTime - lastApiCall;
          var getSeconds = (timeDiff / 1000) ;
          var getMinutes = getSeconds / 60; 
          var actualTimeDifferInMinutes = getMinutes
          if(Cookie.getCookie('reAuth') &&  actualTimeDifferInMinutes >26.5 ){
            this.props.dispatch(userAuthInfo.stillAlive())
          }
          if (actualTimeDifferInMinutes > 27 ) {
            dispatch({type:"SESSION_TIMEOUT_FLAG",payload:true})
          }else{
            if (this.props.sessionTimeOutFlag) {
              dispatch({type:"SESSION_TIMEOUT_FLAG",payload:false})
            }
          }
        }
        if (!Cookie.getCookie('c_ux')) {
          localStorage.oldRedirectURL = currentURL;
          clearInterval(this.logOutCheck)
          if(this.props.user&&this.props.user.name)
            this.props.dispatch(userAuthInfo.logout(websiteAndCustomUrl,this.props.ssoToken));
          } 
        },3000)
    }
    else{
      //this.props.dispatch(userAuthInfo.logout(this.logOutCheck))
    }
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    // added to prevent user opening features by copy paste url if that featuure is not allowed in featureList for client and broker routes

    if (dependencyCheck(newProps.userdata, 'addViewActionType.featureNo') != dependencyCheck(this.props.userdata, 'addViewActionType.featureNo')) {
      const feature = newProps.userdata.addViewActionType.featureNo
      if (newProps.user && (['broker', 'client'].includes(newProps.user.userType)) && feature && (newProps.userdata && newProps.userdata.allowedFeatureList) && (!(newProps.userdata.allowedFeatureList[feature]))) {
        window.location.hash = `#/${newProps.user.userType}/dashboard`
      }
    }
  }

  componentWillUnmount(){
    clearInterval(this.logOutCheck)
    sessionStorage.removeItem('FeatureName')
    sessionStorage.removeItem('isin')
  }

  checkUserRole(){
    let user = this.props.user;
    if(Cookie.getCookie('c_ux')){
        // if(user.uid&&user.userType){
        //       if(user.userType == USER_TYPE.admin){
        //         return true;
        //       }else if(user.userType != USER_TYPE.admin && user.userType != USER_TYPE.client) {
        //         if(this.props.children&&this.props.children.props.route&&(this.props.children.props.route.role != USER_TYPE.admin)){
        //           return true
        //         }
        //       }
        //       if((this.props.children&&this.props.children.props.route&&(this.props.children.props.route.role == user.userType))){
        //         return true;
        //       }
        // }
    }
    return true;
  }

  render() {

    if(this.props.user && this.props.user.uid){
      if (this.checkUserRole()) {
        return this.props.children;
      } 
      else {
        return null
      }
    }
    else{
      return <span>
      <Loader 
      loaderType = 'line'
      loaderWidth = { LOADER_WIDTH[2].width }
      loaderHeight = { LOADER_WIDTH[2].height }
      />
      </span>
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentURL: ownProps.location&&ownProps.location.pathname,
    lastApiCallTime: state.userReducer.lastApiCallTime,
    user:state.userReducer.user,
    userdata :state.userReducer,
    ssoToken :state.userReducer && state.userReducer.SSOuser && state.userReducer.SSOuser.SSOToken,
    SSOuserData :state.userReducer && state.userReducer.SSOuserData,
    sessionTimeOutFlag: state.dashboardReducer.sessionTimeOut,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(AuthenticateUser)

