import * as authAPIList from '../repositories/login'
import  { getUserRepo, GetAllowedFeatureListRepo, GetFeaturePermissionsRepo, UpdatePermissionsRepo, SSOLoginRepo }  from '../repositories/user';
import SessionData from 'app/utils/sessionData'
import {BACK_OFFICE_LEVEL_NO} from 'app/constants/shared/commonConst'
import { stringifyFilter } from 'app/actions/shared/dataFormatter'
import { DOCS_LOCATION_DOMAIN } from 'app/constants'
import {CHECK_FOR_MAINTAINANCE} from 'app/constants/index'
import {changePasswordFromInsideLoginRepo} from '../repositories/user.jsx'

export const GetUserData = (val) => {
  return function(dispatch){
    if(!val) {
      dispatch({type:"GETTING_USER",payload:{}})
    }
    getUserRepo()
    .then((response) => {
      if(response.status == 0){
        dispatch({type: 'COMPONENT_LOADER_CHECKTOKEN', payload:false})
        if(response.result && (response.result.allReportingLevels && response.result.lowerReportingLevels)){
          response.result.allLevels = response.result.lowerReportingLevels
          response.result.backOfficeRLevels = response.result.allReportingLevels.filter(obj => BACK_OFFICE_LEVEL_NO.includes(obj.levelNo))
          response.result.backOfficeRLevelsFromLower = response.result.lowerReportingLevels.filter(obj => BACK_OFFICE_LEVEL_NO.includes(obj.levelNo))
          response.result.allReportingLevels = response.result.allReportingLevels.filter(obj => !BACK_OFFICE_LEVEL_NO.includes(obj.levelNo))
          response.result.lowerReportingLevels = response.result.lowerReportingLevels.filter(obj => !BACK_OFFICE_LEVEL_NO.includes(obj.levelNo))
        }
        dispatch({type:"GOT_USER_SUCCESS",payload:response.result})
      }else {
        dispatch(logout())
        dispatch({type:"USER_FAILED",payload:response.message})
      }
    }).catch((err) =>{
      dispatch(logout())
      dispatch({type:"USER_FAILED",payload:err})
    })
  }
}

export const LoginAction = (param) => {
  return function(dispatch) {
        const encryptedPassword = Math.random().toString(36).substring(2,7) + param.password ;
        param.password= btoa(encryptedPassword)
        param.isEncryption = true
        dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:{componentName:'login'}})
        dispatch({type: 'LOGIN_IN', payload: {}})
        authAPIList.loginRepo(param)
      .then((response) => {
        if(response.status == 0){
          if(response.result && response.result.token){
             dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:null})
             dispatch({type: 'LOGIN_STEP_ONE_SUCCESS', payload:response.result })
          }
          else{
            if(response.result.userType == "broker"){
              window.location.href = '#/broker/dashboard'
            }else if(response.result.userType == "admin"){
              window.location.href = '#/admin/ifaLookup' ;
            }
            else{
                window.location.href = "#/client/dashboard";
            }
            dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:null})
            dispatch({type: 'LOGIN_SUCCESS', payload: response.result})        
          }
        }
        else{
          dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:null})
          dispatch({type: 'LOGIN_FAILED', payload: response.message})
        }
      })
      .catch((err) => {
        dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:null})
        dispatch({type: 'LOGIN_FAILED', payload: err.message})
      })
  }
}
export const SignupAction = (param) => {
  return function(dispatch) {
        dispatch({type: 'SIGNUP_IN', payload: {}})
        authAPIList.signupRepo(param)
      .then((response) => {
        //SET Cookie...
        if(response.status==0){
          window.location.href = "#/client/dashboard" ;
          dispatch({type: 'SIGNUP_SUCCESS', payload: response.result})
        }
        else{
          dispatch({type: 'SIGNUP_FAILED', payload: response.message})
        }
      })
      .catch((err) => {
        dispatch({type: 'SIGNUP_FAILED', payload: err.message})
      })
  }
}

export const logout = (customUrls,allowSSOLogin) => {
  if (window.location.hash.split('?')) {
    SessionData.setItem('authorizationToken',window.location.hash.split('=')[1])
  }else {
    sessionStorage.removeItem('authorizationToken',null)
  }
  SessionData.deleteItem('getActualTime')
  return function(dispatch) {
        dispatch({type: 'COMPONENT_LOADER_LOGOUT', payload:{componentName:'logout'}})
        dispatch({type: 'LOGOUT_IN', payload: {}})
        authAPIList.logoutRepo()
      .then((response) => {
          // dispatch({type: 'COMPONENT_LOADER_LOGOUT', payload:null})
        if(response.status==0){
          localStorage.removeItem('isPopupOpen')
          dispatch({type:'CLEAR_STORE'})
          dispatch({type: 'LOGOUT_SUCCESS', payload: response.message})
          /*if(!(logoutWithoutRedirection&&logoutWithoutRedirection.logout)){
          }*/
          if(customUrls && allowSSOLogin){
            window.open(`http://${customUrls}` ,'_self')
          }
          else{
            window.location.href = '#/login'
             location.reload()
          }
         
          sessionStorage.clear();
         
        }
        else{
          dispatch({type: 'LOGOUT_FAILED', payload: response.message})
        }
      })
      .catch((err) => {
        dispatch({type: 'COMPONENT_LOADER_LOGOUT', payload:null})
        dispatch({type: 'LOGOUT_FAILED', payload: err.message})
      })
  }
}

export const GetUserTheme = (data) => {
  return function(dispatch){
    const redirectURL = 'https://www.investwellonline.com/investwell-mint'
    let maintaiceStatusValid = false
    dispatch({type:"GETTING_THEME",payload:{}})
    dispatch({ type: 'LOADER_FOR_KYC_FORM', payload: true })
    dispatch({type: 'COMPONENT_LOADER_FORGET_PASSWORD', payload:data.componentForLoader})
    authAPIList.getUserThemeRepo(data)
    .then((response) => {
      maintaiceStatusValid = (response && ((response.name =='AxiosError') && CHECK_FOR_MAINTAINANCE.includes(response.response && response.response.status)))
      if(response.status == 0){
        dispatch({type: 'COMPONENT_LOADER_FORGET_PASSWORD', payload:null})
        dispatch({ type: 'LOADER_FOR_KYC_FORM', payload: false })
        dispatch({type:"GOT_THEME_SUCCESS",payload:response.result})
      }else if((response.status == -1) && (response.message == 'broker not found')) {
          location.replace(redirectURL)
        }else{
          if(!maintaiceStatusValid){
            dispatch(logout())
          }
        dispatch({type: 'COMPONENT_LOADER_FORGET_PASSWORD', payload:null})
        dispatch({type:"THEME_FAILED",payload:response.message})
      }
    }).catch((err) =>{
      if(err == 'broker not found'){
        location.replace(redirectURL)
      }else{
        if(!maintaiceStatusValid){
          dispatch(logout())
        }
        dispatch({type:"THEME_FAILED",payload:err})
      }
    })
  }
}
export const stillAlive = (data) => {
  return function(dispatch) {
        dispatch({type: 'CONTINUE_IN', payload: {}})
        authAPIList.continueLoginRepo(data)
      .then((response) => {
        if(response.status==0){
          dispatch({type: 'CONTINUE_SUCCESS', payload:response})
          //location.reload();
          //dispatch({type:'TAB_RELOAD', payload: true})
        }
        else{
          dispatch({type: 'CONTINUE_FAILED', payload: response.message})
        }
      })
      .catch((err) => {
        dispatch({type: 'CONTINUE_FAILED', payload: err.message})
      })
  }
}

export const PassResetMailData = (param) => {
  return function (dispatch) {
    dispatch({ type: 'COMPONENT_LOADER_FORGET_PASSWORD', payload: param.componentForLoader })
    authAPIList.passResetMailRepo(param)
      .then((response) => {
        dispatch({ type: 'COMPONENT_LOADER_FORGET_PASSWORD', payload: null })
        if ([0,-2].includes(response.status)) {
          dispatch({ type: 'GOT_RESETMAIL_DATA', payload: response })
        }
         else {
          dispatch({ type: 'FAILED_RESETMAIL_DATA', payload: response })
        }
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_RESETMAIL_DATA', payload: err })
      })
  }
}

export const CheckTokenData = (param) =>{
  return function(dispatch){
    dispatch({type:'GETTING_CHECKTOKEN_DATA', payload : 'getting data'})
    dispatch({type: 'COMPONENT_LOADER_CHECKTOKEN', payload:true})
    authAPIList.checkTokenRepo(param)
    .then((response) =>{
      dispatch({type: 'COMPONENT_LOADER_CHECKTOKEN', payload:false})
      if(response.status==0){
        dispatch({type:'GOT_CHECKTOKEN_DATA', payload:response.result})
      }else{
        dispatch({type:'FAILED_CHECKTOKEN_DATA', payload : response.message})
      }
    })
    .catch((err) =>{
      dispatch({type:'FAILED_CHECKTOKEN_DATA', payload:err})
    })
  }
}
export const ResetPasswordData = (param) =>{
  const encryptedPassword = Math.random().toString(36).substring(2,7) + param.password ;
  param.password= btoa(encryptedPassword)
  param.isEncryption = true
  return function(dispatch){
    dispatch({type: 'COMPONENT_LOADER_FORGET_PASSWORD', payload:param.componentForLoader})
    authAPIList.resetPasswordRepo(param)
    .then((response) =>{
    dispatch({type: 'COMPONENT_LOADER_FORGET_PASSWORD', payload:null})
      if(response.status==0){
        dispatch({type:'GOT_RESETPASS_DATA', payload:response.result})
      }else
      {
        dispatch({type:'FAILED_RESETPASS_DATA', payload:response.message})
      }
    })
    .catch((err)=>{
      dispatch({type:'FAILED_RESETPASS_DATA', payload:err})
    })
  }
}

export const AdminToBrokerLogin = (param) => {
  return function (dispatch){
    authAPIList.adminToBrokerLoginRepo(param)
    .then((response) =>{
      if(response.status == 0){
        dispatch({type:"GOT_ADMIN_TO_BROKER_INFO",payload:response.result})
        window.location.href = '#/broker/dashboard'
      }
      else{
        dispatch({type:"FAILED_ADMIN_TO_BROKER_INFO",payload:response.message})
      }
    })
    .catch((err) => {
      dispatch({type:"FAILED_ADMIN_TO_BROKER_INFO",payload:err})
    })

  }

}

export const GetAdminToBrokerLoginHistory = (param) => {
	param = stringifyFilter(param)
	return function (dispatch) {
		dispatch({ type: 'COMPONENT_LOADER_TABLE', payload: true })
		authAPIList.getAdminToBrokerLoginHistoryRepo(param)
			.then((response) => {
				dispatch({ type: 'COMPONENT_LOADER_TABLE', payload: false })
				if (response.status == 0) {
					dispatch({ type: "GOT_ADMIN_TO_BROKER_LOGIN_HISTORY", payload: response.result })
				}
				else {
					dispatch({ type: "FAILED_ADMIN_TO_BROKER_LOGIN_HISTORY", payload: response.messge })
				}
			})
			.catch((err) => {
				dispatch({ type: "FAILED_ADMIN_TO_BROKER_LOGIN_HISTORY", payload: err })
			})
	}
}

export const LoginWithGoogle = (param,savedDomainNameForGoogle) => {
  return function (dispatch){
    authAPIList.loginWithGoogleRepo(param)
    .then((response) =>{
          localStorage.removeItem('isPopupOpen')
      if(response.status == 0){
              let paramObj = {
                  mapToken: response.result.data.mapToken  
              }
              let getParamList = "";
              for (let key in paramObj) {
                if (getParamList != "") {
                  getParamList += "&";
                }
                getParamList += key + "=" + encodeURIComponent(paramObj[key]);
              }
              let createUrl = "https://"+savedDomainNameForGoogle+"/app/#/login?" + getParamList
              window.open(createUrl,'_self');
              dispatch({type: 'GOT_AUTHORIZATION_KEY', payload: null})
      }else{
          let paramObjList = {
            message: response.message  
          }
          let getParams = "";
              for (let key in paramObjList) {
                if (getParams != "") {
                    getParams += "&";
                }
                 getParams += key + "=" + encodeURIComponent(paramObjList[key]);
              }
        let customUrl = "https://"+savedDomainNameForGoogle+"/app/#/login?" + getParams
        window.open(customUrl,'_self');
        dispatch({type:"FAILED_TO_LOGIN_WITH_GOOGLE",payload:response.message})
      }
    })
    .catch((err) => {
      localStorage.removeItem('isPopupOpen')
      let createUrl = "https://"+savedDomainNameForGoogle+".investwell.app/app/#/login"
      window.open(createUrl,'_self');
      dispatch({type:"FAILED_TO_LOGIN_WITH_GOOGLE",payload:err})
    })

  }

}



export const SendOTP = (param) => {
    return function (dispatch){
        authAPIList.sendOTPRepo(param).then((response) =>{
            if(response.status == 0){
                dispatch({type:"OTP_SENT_SUCCESSFULLY",payload:response})
            }
            else{
                dispatch({type:"FAILED_TO_SEND_OTP",payload:response})
            }
        })
        .catch((err) => {
            dispatch({type:"FAILED_TO_SEND_OTP",payload:err})
        })
    }
}

export const SendMobileOTP = (param) => {
  return function (dispatch) {
    dispatch({ type: 'LOADER_SENT_OTP', payload: true })
    authAPIList.sendMobileOTPRepo(param)
    .then((response) => {
      dispatch({ type: 'LOADER_SENT_OTP', payload: false })
      if (response.status == 0) {
        SessionData.deleteItem("otpDetails")
        dispatch({ type: "OTP_SENT_SUCCESSFULLY", payload: response })
      }
      else {
        dispatch({ type: "FAILED_TO_SEND_OTP", payload: response })
      }
    })
      .catch((err) => {
        dispatch({ type: "FAILED_TO_SEND_OTP", payload: err })
      })
  }
}

export const ConfirmOTP = (param) => {
    return function (dispatch){
      dispatch({ type: 'LOADER_SENT_OTP', payload: true })
        authAPIList.confirmOTPRepo(param).then((response) =>{
          dispatch({ type: 'LOADER_SENT_OTP', payload: false })
            if(response.status == 0){
                SessionData.deleteItem("otpDetails")
                dispatch({type:"CONFIRM_OTP_SUCCESS",payload:response.result})
                if(response.result.userType == "broker"){
                  window.location.href = '#/broker/dashboard'
                }else if(response.result.userType == "admin"){
                  window.location.href = '#/admin/report/oneViewReport' ;
                }
                else{
                  window.location.href = "#/client/dashboard";
                }
                dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:null})
                dispatch({type: 'LOGIN_SUCCESS', payload: response.result})
            }
            else{
                dispatch({type:"FAILED_OTP_CONFIRMATION",payload:response.message})
                dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:null})
            }
        })
        .catch((err) => {
            dispatch({type:"FAILED_OTP_CONFIRMATION",payload:err})
            dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:null})
        })
    }
}

export const GetOTPExpiry = (param) => {
    return function (dispatch){
        authAPIList.GetOTPExpiryRepo(param).then((response) =>{
            if(response.status == 0){
                dispatch({type:"GOT_OTP_EXPIRY_TIME_SUCESSFULLY",payload:response.result})
            }
            else{
                dispatch({type:"FAILED_GETTING_OTP_EXPIRY",payload:response.message})
            }
        })
        .catch((err) => {
            dispatch({type:"FAILED_GETTING_OTP_EXPIRY",payload:err})
        })
    }
}


export const GetAllowedFeatureList = userType => {  
  return function(dispatch){
    GetAllowedFeatureListRepo(userType)
    .then( response => {
      if(response.status == 0){
        dispatch({type:"GOT_ALLOWED_FEATURE_LIST",payload:response.result})
      }else {
        dispatch({type:"FAILED_ALLOWED_FEATURE_LIST",payload:response.message})
      }
    }).catch((err) =>{
      dispatch({type:"FAILED_ALLOWED_FEATURE_LIST",payload:err})
    })
  }
}

export const GetFeaturePermissions = data => {  
  return function(dispatch){
    dispatch({type:"COMPONENT_LOADER_TABLE",payload:true})
    GetFeaturePermissionsRepo(data)
    .then( response => {
      dispatch({type:"COMPONENT_LOADER_TABLE",payload:false})
      if(response.status == 0){
        dispatch({type:"GOT_FEATURE_PERMISSIONS",payload:response.result})
        dispatch({type:"STORE_PREVIOUS_DATA",payload:response.result})
      }else {
        dispatch({type:"FAILED_TO_GET_FEATURE_PERMISSIONS",payload:response.message})
      }
    }).catch((err) =>{
      dispatch({type:"COMPONENT_LOADER_TABLE",payload:false})
      dispatch({type:"FAILED_TO_GET_FEATURE_PERMISSIONS",payload:err})
    })
  }
}

export const UpdatePermissions = data => {  
  return function(dispatch){
    dispatch({type:"COMPONENT_LOADER_TABLE",payload:true})  
    UpdatePermissionsRepo(data)
    .then( response => {
      if(response.status == 0){
        dispatch({type:"COMPONENT_LOADER_TABLE",payload:false})  
        dispatch({type:"UPDATE_FEATURE_PERMISSIONS",payload:response})
      }else {
        dispatch({type:"FAILED_TO_UPDATE_FEATURE_PERMISSIONS",payload:response})
      }
    }).catch((err) =>{
      dispatch({type:"COMPONENT_LOADER_TABLE",payload:false})    
      dispatch({type:"FAILED_TO_UPDATE_FEATURE_PERMISSIONS",payload:err})
    })
  }
}

export const SSOLogin = (param) => {
  return function (dispatch){
    SSOLoginRepo(param)
    .then((response) =>{
      if(response.status == 0){
        dispatch({type: 'COMPONENT_LOADER_LOGIN', payload:null})
        dispatch({type: 'SSO_LOGIN_IN', payload:param })
        if(sessionStorage.getItem('FeatureName')){
          dispatch({type: 'SSO_LOGIN_IN_RESPONCE', payload:response })
        }
        //dispatch({type:"GOT_ADMIN_TO_BROKER_INFO",payload:response.result})
        // location.reload()
      }
      else{
        dispatch({type:"LOGIN_FAILED",payload:response.message})
      }
    })
    .catch((err) => {
      dispatch({type:"LOGIN_FAILED",payload:err})
    })

  }

}
export const CheckMaintainance = (param) =>{
  return function(dispatch){
    authAPIList.checkMaintainance(param)
    .then((response) =>{
      if(response.status==200){
        dispatch({type:'CHECK_MAINTAINANCE_SUCCESS', payload:response})
      }else
      {
        dispatch({type:'FAILED_CHECK_MAINTAINANCE', payload:response})
      }
    })
    .catch((err)=>{
      dispatch({type:'FAILED_CHECK_MAINTAINANCE', payload:err})
    })
  }
}
export const SubscribeApi = (param) =>{
  return function(dispatch){
    dispatch({type: 'COMPONENT_LOADER_SUBSCRIBE', payload:true})
    authAPIList.subscribeApiRepo(param)
    .then((response) =>{
      dispatch({type: 'COMPONENT_LOADER_SUBSCRIBE', payload:false})
      if(response.status==0){
        dispatch({type:'SUCCESSFULL_SUBSCRIBE', payload:response})
      }else
      {
        dispatch({type:'FAILED_SUBSCRIBE', payload:response.message})
      }
    })
    .catch((err)=>{
      dispatch({type:'FAILED_SUBSCRIBE', payload:err})
    })
  }
}
export const ChangePasswordData = (param) => {
  const encryptedOldPassword = Math.random().toString(36).substring(2,7) + param.oldPassword;
  const encryptedNewPassword = Math.random().toString(36).substring(2,7) + param.newPassword;
  param.oldPassword = btoa(encryptedOldPassword);
  param.newPassword = btoa(encryptedNewPassword);
  param.isEncryption = true;

  return function(dispatch) {
    dispatch({ type: 'COMPONENT_LOADER_SUBSCRIBE', payload: true });
    changePasswordFromInsideLoginRepo(param)
      .then((response) => {
        dispatch({ type: 'COMPONENT_LOADER_SUBSCRIBE', payload: false });
        
        if (response.status === 0) {
          dispatch({ type: 'GOT_CHANGEPASS_DATA', payload: response });
        } else {
          dispatch({ type: 'FAILED_CHANGEPASS_DATA', payload: response });
        }
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_CHANGEPASS_DATA', payload: err });
      });
  };
};