export default function investOnlineMfu(state={},action){
	switch(action.type){
		case "GOT_CAN_LIST_DATA":
			return {...state,canListingData:action.payload}
		case "COMPONENT_LOADER_CAN_LISTING":
			return {...state,loaderCanListData:action.payload}
		case "FAILED_CAN_LIST_DATA":
			return {...state,canListingData:action.payload}

		case "GOT_CAN_LIST_SUMMARY_DATA":
			return {...state,canListingSummary:action.payload}
		case "FAILED_CAN_LIST_SUMMARY_DATA":
			return {...state,canListingSummary:action.payload}

		case "GOT_EDIT_CAN_DATA":
			return {...state,editCanData:action.payload}
		case "COMPONENT_LOADER_EDIT_CAN_DATA":
			return {...state,loaderEditCanData:action.payload}
		case "FAILED_EDIT_CAN_DATA":
			return {...state,editCanData:action.payload}

		case "GOT_CAN_PROFILE_DATA":
			return {...state,editCanProfileData:action.payload}
		case "COMPONENT_LOADER_CAN_PROFILE_DATA":
			return {...state,loaderEditCanProfile:action.payload}
		case "FAILED_CAN_PROFILE_DATA":
			return {...state,editCanProfileData:action.payload}

		case "GOT_MANDATE_LIST_DATA":
			return {...state,mandateListdata:action.payload}
		case "COMPONENT_LOADER_MANDATE_LISTING":
			return {...state,loaderMandateListing:action.payload}
		case "FAILED_MANDATE_LIST_DATA":
			return {...state,mandateListdata:action.payload}

		case "GOT_MFU_MANDATE_LIST_DATA":
			return {...state,mandateListdata:action.payload}
		case "COMPONENT_LOADER_MANDATE_LISTING":
			return {...state,loaderMandateListing:action.payload}
		case "FAILED_MFU_MANDATE_LIST_DATA":
			return {...state,mandateListdata:action.payload}

		case "GOT_MANDATE_SUMMARY_DATA":
			return {...state,mandateSummary:action.payload}
		case "COMPONENT_LOADER_MANDATE_SUMMARY":
			return {...state,loaderMandateSummary:action.payload}
		case "FAILED_MANDATE_SUMMARY_DATA":
			return {...state,mandateSummary:action.payload}

		case "GOT_CAN_LIST_UNDER_USER_DATA":
			return {...state,canListUnderUser:action.payload}
		case "COMPONENT_LOADER_CAN_LIST_UNDER_USER":
			return {...state,loaderCanListUnderUser:action.payload}
		case "FAILED_CAN_LIST_UNDER_USER_DATA":
			return {...state,canListUnderUser:action.payload}

		case "GOT_MFU_CLIENTS_DATA":
			return {...state,investorsData:action.payload}
		case "COMPONENT_LOADER_MFU_CLIENTS":
			return {...state,loaderMfuClients:action.payload}
		case "FAILED_MFU_CLIENTS_DATA":
			return {...state,investorsData:action.payload}

		case "GOT_CAN_DOCUMENT_LIST":
			return {...state,canDocumentList:action.payload}
		case "COMPONENT_LOADER_CAN_DOCUMENT_LIST":
			return {...state,loaderCanDocument:action.payload}
		case "FAILED_CAN_DOCUMENT_LIST":
			return {...state,canDocumentList:action.payload}

		case "GOT_REGISTER_MANDATE_DATA":
			return {...state,registerMandateData:action.payload,notificationData:action.payload}
		case "COMPONENT_LOADER_REGISTER_MANDATE_MFU":
			return {...state,loaderRegisterMandate:action.payload}
		case "FAILED_REGISTER_MANDATE_DATA":
			return {...state,registerMandateData:action.payload,notificationData:action.payload}

		case "GOT_CREATE_PERSONAL_INFO_DATA":
			return {...state,createPersonalInfoData:action.payload,notificationData:action.payload}
		case "COMPONENT_LOADER_CREATE_PERSONAL_INFO":
			return {...state,loaderCreateProfileInfo:action.payload}
		case "FAILED_CREATE_PERSONAL_INFO_DATA":
			return {...state,createPersonalInfoData:action.payload,notificationData:action.payload}

		case "GOT_REGISTRATION_DATA":
			return {...state,registrationData:action.payload,notificationData:action.payload}
		case "COMPONENT_LOADER_REGISTRATION":
			return {...state,loaderRegistration:action.payload}
		case "FAILED_REGISTRATION_DATA":
			return {...state,registrationData:action.payload,notificationData:action.payload}

		case "GOT_NOMINEE_DETAILS_DATA":
			return {...state,nomineeDetails:action.payload,notificationData:action.payload}
		case "COMPONENT_LOADER_NOMINEE_DETAILS":
			return {...state,loaderNomineeDetails:action.payload}
		case "FAILED_NOMINEE_DETAILS_DATA":
			return {...state,nomineeDetails:action.payload,notificationData:action.payload}

		case "GOT_BANK_DETAILS_DATA":
			return {...state,bankDetailsData:action.payload,notificationData:action.payload}
		case "COMPONENT_LOADER_BANK_DETAILS":
			return {...state,loaderBankDetails:action.payload}
		case "FAILED_BANK_DETAILS_DATA":
			return {...state,bankDetailsData:action.payload,notificationData:action.payload}

		case "GOT_FATCA_DETAILS_DATA":
			return {...state,fatcaDetailsData:action.payload,notificationData:action.payload}
		case "COMPONENT_LOADER_FATCA_DETAILS":
			return {...state,loaderFatcaDetails:action.payload}
		case "FAILED_FATCA_DETAILS_DATA":
			return {...state,fatcaDetailsData:action.payload,notificationData:action.payload}

		case "GOT_SUBMIT_DOC_MFU_DATA":
			return {...state,submitDocData:action.payload,notificationData:action.payload}
		case "COMPONENT_LOADER_SUBMIT_DOC_MFU":
			return {...state,loaderSubmitDoc:action.payload}
		case "FAILED_SUBMIT_DOC_MFU_DATA":
			return {...state,submitDocData:action.payload,notificationData:action.payload}

		case "GOT_SUBMIT_DOCUMENT_MFU_DATA":
			return {...state,submitDocumentData:action.payload,notificationData:action.payload}
		case "FAILED_SUBMIT_DOCUMENT_MFU_DATA":
			return {...state,submitDocumentData:action.payload,notificationData:action.payload}

		case "GOT_MFU_BANK_MASTER_DATA":
			return {...state,mfuBankMasterData:action.payload}
		case "FAILED_MFU_BANK_MASTER_DATA":
			return {...state,mfuBankMasterData:action.payload}

		case "GOT_MFU_COUNTRY_MASTER_DATA":
			return {...state,mfuCountryMasterData:action.payload}
		case "FAILED_MFU_COUNTRY_MASTER_DATA":
			return {...state,mfuCountryMasterData:action.payload}

		case "LOGIN_USER_TYPE":
			return {...state,userType:action.payload}

		case 'CLEAR_NOTIFICATION':
			return{...state,notificationData:action.payload}

		case 'STEP_NO_MFU_FORM':
			return {...state,stepNo:action.payload}

		case 'STEP_NO_MFU_FORM_FLAG':
			return {...state,stepNoFlag:action.payload}

		case 'SAVE_AND_EXIT':
			return {...state,saveAndExit:action.payload}

		case 'INDEX_UPLOAD_DOC':
			return {...state,indexUploadDoc:action.payload}

		case 'ON_PAN_CHANGE_FLAG':
			return {...state,CheckKYCFlag:action.payload}

		case 'NOMINEES_DETAILS_DATA':
			return {...state,savedNomineeDetailsData:action.payload}

		case 'PREFILED_NOMINEES_DETAILS_DATA':
			return {...state,prefilledNomineeDetails:action.payload}

		case 'EDIT_NOMINEES_DETAILS_DATA':
			return {...state,editNomineeDetailsData:action.payload}

		case 'DELETE_NOMINEES_DETAILS_DATA':
			return {...state,deleteNomineeDetailsData:action.payload}

		case 'EDIT_BANK_DETAILS_DATA':
			return{...state,editBankDetails:action.payload}

		case 'BANK_DETAILS_DATA':
			return{...state,savedBankDetails:action.payload}

		case 'DELETE_BANK_FROM_LIST':
			return{...state,deleteBankData:action.payload}

		case 'SET_AS_DEFAULT_BANK':
			return{...state,defaultBankData:action.payload}

		case 'PREFILLED_BANK_DETAILS_DATA':
			return{...state,prefilledBankDetails:action.payload}

		case 'EDIT_FATCA_DETAILS_DATA':
			return{...state,editFatcaDetails:action.payload}

		case 'FATCA_DETAILS_FINISH_FLAG':
			return{...state,fatcaDetailsFinish:action.payload}

		case 'ALREADY_FATCA_DONE_FLAG':
			return{...state,fatcaCompleted:action.payload}

		case 'SHOW_HIDE_NOTIFICATION_MSG':
			return {...state,showHideNotification:action.payload}

		case 'CREATE_PROFILE_SUBMIT_OBJECT':
			return {...state,createProfileSubmitObj:action.payload}

		case "GOT_BULK_UPLOAD_MFU_DATA":
			return {...state,bulkuploadData:action.payload}
		case "COMPONENT_LOADER_BULK_UPLOAD_MFU":
			return {...state,loaderBulkUploadMFU:action.payload}
		case "FAILED_BULK_UPLOAD_MFU_DATA":
			return {...state,bulkuploadData:action.payload}

		case "COMPONENT_LOADER_BULK_UPLOAD_MFU_MANDATE":
			return {...state, loaderBulkUploadMFU: action.payload }
		case "GOT_BULK_UPLOAD_MFU_MANDATE":
			return { ...state, bulkUploadMFUMandate: action.payload }
		case "FAILED_BULK_UPLOAD_MFU_MANDATE":
			return { ...state, bulkUploadMFUMandate: action.payload }

		case "SUCCESS_DELETE_PROFILE":
			return {...state, deletedProfile:action.payload, notificationData:action.payload}
		case "FAILED_DELETE_PROFILE":
			return {...state, deletedProfile:action.payload, notificationData:action.payload}

		case 'COMPONENT_LOADER_PROFILE_LIST': 
			return{...state, loaderProfileList:action.payload}

			
	}
	return state
}