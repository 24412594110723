export const genericAPIStructure = {
	result: null,
	fetching: false,
	fetched: false
}

export const USER_TYPE = {
	broker:'broker',
	client:'client',
	admin:'admin',
}

export const UTIL = {
	dateFormat:"YYYY-MM-DD",
	frontDateFormat:"DD-MM-YYYY",
	dateAndTimeFormat: "DD-MM-YYYY  hh:mm A",
	showMonthAndYearOnly : "MM-YYYY",
	showDayAndMonthOnly : "DD-MM",
	dateMonthAndTime: "DD-MM  hh:mm A",
	dateTimeSecFormate : "DD-MM-YYYY  hh:mm:ss A",
  fullTimeIn24hours:"HH:mm:ss",
  fullTimeIn12hours:"hh:mm:ss",
  kycFormat : "DD/MM/YYYY",
  monthInWordFormat: "DD MMM YYYY"
}

export const FEATURE_PERMISSIONS = ['add','view','delete','export']

export const otpLength = 6

export const TRANSACTION_DELETE_MSG = ["This will delete all the information related to this Asset. Are you sure ?"]

export const CLIENT_INFO_ONE = [
    { label: 'PAN', value: 'pan' , defaultIndex: 0},
    { label: 'Landline no', value: 'landline', defaultIndex: 0},
    { label: 'Address', value: ['address1', 'address2', 'address3', 'city', 'pin'] , defaultIndex: 0 },
    { label: 'Date of Birth', value: 'dob', dateFormatter: true, defaultIndex: 0},
    { label: 'Anniversary Date', value: 'anniversary', dateFormatter: true, defaultIndex: 0},
  ]

export const CLIENT_INFO_TWO = [
    { label: 'AUM', value: 'aum', valueFormatter: true ,defaultIndex: 1 },
    { label: 'Family AUM', value: 'familyAum', valueFormatter: true, defaultIndex: 1 },
    { label: 'Last Portfolio Review', value: 'lastReviewDate', dateFormatter: true , defaultIndex: 0 },
    { label: 'Goal Amount', value: 'goalAmount', valueFormatter: true, defaultIndex: 1 },
    { label: 'Current SIPs', value: 'sip', valueFormatter: true, defaultIndex: 1 },
    { label: 'Current STPs', value: 'stp', valueFormatter: true, defaultIndex: 1 },
    { label: 'Current SWPs', value: 'swp', valueFormatter: true, defaultIndex: 1 },
    { label: 'Last Purchase', value: 'lastPurchaseDate', dateFormatter: true, defaultIndex: 0 },
    { label: 'Created Date', value: 'createdAt', dateFormatter: true, defaultIndex: 0 },
  ]

export const MOBILE_OS_PLATFORM = [
  { levelName: 'Android', levelNo: 'ANDROID', fileSize: '50', fileFormat: 'json' },
  { levelName: 'IOS', levelNo: 'IOS', fileSize: '50', fileFormat: 'txt' }
]
export const DOCS_LOCATION_DOMAIN = 'https://staging-ftp.mfexchange.com'
export const INVESTWELL_CLOUD_IMAGES = 'cloud_investwell_images'
export const INVESTWELL_LOGOS = 'logo'
export const INVESTWELL_TEMPLATE_IMAGES= 'TemplateImages'
export const INVESTWELL_LARGE_LOGOS= 'Large'
export const INVESTWELL_SMALL_LOGOS= 'Small'
export const INVESTWELL_CUSTOM_IMAGES= 'CustomImages'
export const INVESTWELL_FUNDS_IMAGES= 'Funds'
export const INVESTWELL_MEETING_NOTES_IMAGE = 'MeetingNotes'
export const CHECK_FOR_MAINTAINANCE = [503, 502, 504, 404]

export const KYC_STEPS = {
  '0': 'mobileSignUp',
  '1': 'panVerification',
  '1.1': 'proofOfIdentity',
  '1.2': 'proofOfAddress',
  '2': 'holdings',
  '3': 'nominee',
  '4': 'bankDetails',
  '5':'signature',
  '6':'photoVerification',
  '7':'videoVerification',
  '8':'reviewDetails',
  '9':'submittingDetails'
}

export const FEATURE_NAME_ALONG_WITH_URL = {
  Portfolio_Valuation: 'portfolioValuation',
  Portfolio_Summary: 'portFolioSummary/mutualFund',
  NSE_New_Investment: 'newInvestment/allSchemes',
  BSE_New_Investment: 'bseNewInvestment/allSchemes',
  MFU_New_Investment: 'mfuNewInvestment/allSchemes',
  NSE_Additional_Transaction: 'additionalInvestment',
  BSE_Additional_Transaction: 'bseAdditionalInvestment',
  MFU_Additional_Transaction: 'mfuAdditionalInvestment',
  NSE_NFO_Schemes: 'newInvestment/nfoSchemes',
  BSE_NFO_Schemes: 'bseNewInvestment/nfoSchemes',
  MFU_NFO_Schemes: 'mfuNewInvestment/nfoSchemes',
  Goal_Planning: 'goal/goalList',
  Risk_Profiling: 'riskProfiling'
}
